<template>
  <div id="lang" style="float:right">
    <div class="xs-px-10 float-l">
      <a href="javascript:void(0)" @click="setLang('es')" class=" typo-l6">
        <img src="../../assets/images/icons/br.png" class="xs-mr-5">Español
      </a>
    </div>
    <div class="float-l slat"></div>
    <div class="xs-px-10 float-l">
      <a href="javascript:void(0)" @click="setLang('en')" class=" typo-l6">
        <img src="../../assets/images/icons/en.png" class="xs-mr-5">English
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'lang',
  methods: {
    setLang: function (lang) {
      this.$store.dispatch('setLang', lang)
      this.$session.set('lang', lang)
      this.$router.go(this.$router.currentRoute)
    }
  }
}
</script>
