<template>
<div>
  <div class="confirm-modal" tabindex="-1" role="dialog" ref="vue_confirm_modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')"><span aria-hidden="true">&times;</span></button>
          <h3 class="modal-title">{{$t('modal.confirm_clear_store_title')}}</h3>
        </div>
        <div class="modal-body">
          <p style="font-size:15px;">{{$t('modal.clear_cart_session_message')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="clearAndCreateNewStore(outlet),$emit('close')">{{$t('modal.ok_create_for_new')}}</button>
          <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('close')">{{$t('modal.no_kept_this_store')}}</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['outlet'],
  methods: {
    clearAndCreateNewStore: function (outlet) {
      this.$root.$emit('clearAndCreateNewStore', outlet)
    }
  }
}
</script>
<style scoped>

</style>
