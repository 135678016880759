<template>
  <div id="header-home-image">

    <div id="header-top">
      <div class="home-localise-cta xs-flex xs-justify-start xs-items-center xs-flex-column home-localise-cta--c5 xs-mnb-30 md-mb-0">
      </div>
    </div>
    

    <div v-if="showLocation" data-module-name="localisation-tool" data-module-placement="hero"
         class="home-localise-content xs-z-200 new-disposition ">
      <div class="focus-bg" v-bind:class="{active: $route.query.checkDeals || $route.query.focusLocalization}" v-on:click="hideFocusBg"></div>
      <div class="localisation-form xs-relative xs-mx-auto xs-z-400">
        <img :src="$const.IMAGES.HOME_TEXT_TOP" class="img-text-top" style="display: none" />

        <home-localization-map-asia v-if="$const.LOCALIZATION_TYPE ===$const.LOCALIZATION_TYPES.MAP_ASIA"></home-localization-map-asia><!--phi,sin,malay-->
        <home-localization-map v-else-if="$const.LOCALIZATION_TYPE ===$const.LOCALIZATION_TYPES.MAP"></home-localization-map> <!--japan-->
        <home-localization-map-google-api v-else-if="$const.LOCALIZATION_TYPE ===$const.LOCALIZATION_TYPES.MAP_GOOGLE_API"></home-localization-map-google-api><!--new zealand-->

        <img :src="$const.IMAGES.HOME_CARD" class="img-card" style="display: none" />
        <div class="home-description" v-html="$t('home_description')" style="display: none"></div>
      </div>
    </div>
  </div>

</template>
<script>
import HomeLogin from '@/components/home/HomeLogin.vue'
import HomeLocalizationMapAsia from './HomeLocalizationMapAsia.vue'
import HomeLocalizationMap from './HomeLocalizationMap.vue'
import HomeLocalizationMapGoogleApi from './HomeLocalizationMapGoogleApi.vue'
import UserTopMobile from '../partials/UserTopMobile'

export default {
  components: {
    HomeLocalizationMap,
    HomeLocalizationMapAsia,
    HomeLocalizationMapGoogleApi,
    UserTopMobile
  },
  props: {
    showLocation: {
      type: Boolean,
      default: true
    }
  },
  mounted () {},
  data () {
    return {
      lang: this.$session.get('lang'),
      flag: false,
      client: 1,
    }
  },
  methods: {
    toggleLogin () {
      this.flag = !this.flag
    },
    changeLang (la) {
      this.lang = la
      this.$store.dispatch('setLang', la)
      this.$session.set('lang', la)
      this.$router.go(this.$router.currentRoute)
    },
    hideFocusBg() {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          focusLocalization: undefined,
          checkDeals: undefined
        }
      })
    }
  }
}
</script>
<style scoped>
  .dropdown-lang button {
    border: none;
  }
  .dropdown-lang .dropdown-menu>li>a{
    padding: 3px 12px;
  }
</style>
