<template>
  <div id="delivery" style="box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 22px 0px;">
    <div >
      <div class="xs-flex text-glitch-fix">
        <div data-item-id="localisation-delivery" data-item-type="tool" data-item-position="1" data-synth="form-delivery" data-type="DELIVERY"
             class="order-type-button xs-flex-center xs-flex-auto xs-min-width-0 xs-self-end xs-p-10 bold rounded-top no-outline xs-nowrap text-glitch-fix "
             v-bind:class="isDelivery" role="button" style="margin-right: 2px; font-size: 16px;" tabindex="0"
             v-on:click="clickActiveDelivery">
          <i class="icon-delivery xs-mr-5 xs-hide md-block"></i><span>{{$t('delivery')}}</span>
        </div>
        <div data-item-id="localisation-takeaway" data-item-type="tool" data-item-position="2" data-synth="form-collection" data-type="CARRYOUT"
             class="order-type-button xs-flex-center xs-flex-auto xs-min-width-0 xs-self-end xs-p-10 bold rounded-top no-outline xs-nowrap text-glitch-fix"
             v-bind:class="isCollection" role="button" style="font-size: 16px;" tabindex="0"
             v-on:click="clickActiveCollection">
          <i class="icon-collection xs-mr-5 xs-hide md-block"></i><span>{{$t('collection')}}</span>
        </div>
      </div>
      <div class="localisation xs-fill-white xs-p-15 rounded-bottom">
        <div v-if="orderType ===$const.CARRYOUT">
          <p> <span class="fw600">{{$t('localization.search_by_address_zipcode')}}</span></p>
        </div>
        <div v-else>
          <p> <span class="fw600">{{$t('localization.search_by_address')}}</span></p>
        </div>

        <div class="xs-flex xs-items-end xs-border xs-border-gray-light xs-border-1 rounded xs-relative xs-z-300 xs-fill-white xs-mb-10 xs-items-center"
          style="padding-right: 3px">
          <div class="xs-flex-1" style="margin-right: -3px;">
            <input v-if="orderType ===$const.CARRYOUT" class="search search--hut input xs-border-none xs-py-10 xs-px-10" id="address-autocomplete"
                   data-synth="address-input" name="address" autocomplete="nope" autocorrect="off" autocapitalize="off"
                   spellcheck="false" v-bind:placeholder="$t('localization.placehover_search_by_collection')" value="" type="text" v-model="searchAutoComplete">

            <input v-else class="search search--hut input xs-border-none xs-py-10 xs-px-10"
                   data-synth="address-input" name="address" autocomplete="off" autocorrect="off" autocapitalize="off"
                   spellcheck="false" v-bind:placeholder="$t('localization.placehover_search_by_address')" value="" type="text"  v-model="searchAutoComplete">
          </div>
          <div>
            <button class="buttonload button button--primary flex-1 t13" v-if="loadingFreeText">
              <i class="fa fa-refresh fa-spin"></i>{{$t('button_loading')}}
            </button>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="contain-outlet" v-if="mode=='free'">
          <div v-if="outletAddressOrigin" class="outlet-input-address-form">
            <form id="frmSubmitOutletAddress">
              <div class="form-group">
                <p><span class="fw600" for="outletInputAddress">{{$t('localization.title_outlet_address_chosen')}}</span></p>
                <input type="text" name="address-of-outlet" class="form-control" maxlength="200"
                  v-model.trim="outletAddressOrigin.address" style="min-height:46px;"
                  id="outletInputAddress" aria-describedby="outletAddressHelp" placeholder="Outlet address">
                  <div class="invalid-feedback" v-if="$v.$error">
                    <p class="error" v-if="!$v.outletAddressOrigin.address.required">Address is required.</p>
                  </div>
              </div>
              <a class="btn btn-success pull-right mt-10"
              @click.prevent="clickChosenAddress(outletAddressOrigin,1)">{{$t('localization.outlet_form_order_now')}}</a>
            </form>
          </div>
          <div v-else class="outlet-list-data">
            <ul class="list-outlets" v-if="addressList.length > 0 && searchAutoComplete.length > 0">
              <li v-for="(outlet,index) in addressList" :key="index"
                class="outlet-item inactive text-black" @click.prevent="onclickOutletAddressItem(outlet)">
                  <h4>
                    <span class="text-capitalize"><i class="fa fa-map-marker "></i> {{outlet.address}}</span>
                  </h4>
              </li>
            </ul>
          </div>
        </div>

        <div class="clearfix"></div>

        <div v-if="orderType ===$const.CARRYOUT">
          <div style="height: 30px;">
            <button data-item-id="localisation-auto" data-item-type="tool" data-item-position="4"
            v-if="searchAutoComplete.length <= 0" class="trigger-location use-current-location flex xs-items-center pull-left"
            v-on:click="useMyLocation" style="color: #4a90e2"><i class="icon-geo mr-10"></i> {{$t('using_my_local_carryout')}}</button>
            <a href="javascript:void(0)" class="cursor-pointer cl-78838c border-b t13 pull-right decoration-none"
            v-if="outletList.length > 1" v-on:click="showHideMap(mapOpen)">{{ $t('button_open_maps')}}</a>
          </div>

          <div class="clearfix"></div>

          <div class="contain-outlet" v-if="mode=='location'">
            <ul class="list-outlet-detail" v-if="outletList.length > 0">
              <li v-for="(outlet,index) in outletList" :key="index" class="outlet-item inactive text-black"
                v-bind:class="{highlight: index ===highlight}">
                <div class="row inline">
                  <div class="col-md-9">
                    <h4> {{index +1}}. <span class="text-capitalize">{{outlet.name}}
                      <span class="t12"> ( {{formatNum(outlet.distance)}} kilometers )</span></span>
                    </h4>
                    <p>{{outlet.address}}</p>
                  </div>
                  <div class="col-md-3">
                    <a @click.prevent="clickChosenOutlet(outlet)" class="btn btn-success">Select</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div v-if="outletList.length <= 0  && addressList.length <= 0 && message.length >0" class="pt-10">
          <hr class="m-5">
          <p class="flex1 display-flex items-center">
            <img src="https://static.phdvasia.com/mx/icons/error.svg" width="20" class= "" style="height:20px;flex: 0 0 30px;">
            <span  style="">{{message}}</span>
          </p>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import ConfirmOrderAdvance from '../popup/ConfirmOrderAdvance.vue'
import ModalDelivery from '../popup/ModalDelivery.vue'
import ModalCarryout from '../popup/ModalCarryout.vue'
import ModalMap from '../popup/ModalMaps.vue'
import MaskedInput from 'vue-masked-input'
let active = 'xs-fill-white xs-text-red xs-relative xs-z-200 xs-py-15'
let inactive = 'xs-fill-gray-lightest xs-text-gray-medium xs-grayscale'
var keApi = ''
var region = ''
var language = ''
export default {
  name: 'localization',
  mixins: [validationMixin],
  validations: {
    outletAddressOrigin: {
      address: {
        required
      }
    }
  },
  mounted () {
    keApi = process.env.VUE_APP_GOOGLE_MAP_API_KEY
    region = this.$const.COUNTRY_CODE
    language = this.$session.get('lang') || process.env.VUE_APP_DEFAULT_LANGUAGE
    var vm = this
    window.infowindowClick = function (index) {
      vm.infowindowClick(index)
    }
    this.$disposition.init(this)
    this.$root.$on('onChangeOutlet', (index) => {
      vm.onChangeOutlet(index)
    })
    this.$root.$on('onclickChosenOutlet', (outlet) => {
      vm.clickChosenOutlet(outlet)
    })
    this.$root.$on('activeCollectionMap', () => {
      this.clickActiveCollection()
      this.searchLocation = true
      this.searchAutoComplete = this.addressSelected
      this.getLatLog(this.addressSelected)
    })
    this.$root.$on('collectionTimeUpdate', (time) => {
      this.clickChosenAddress(this.outletAddressOrigin, 0)
    })
  },
  watch: {
    searchZipCode: function (newValue, oldValue) {
      if (!newValue) {
        this.searchByZipCode()
      }
    },
    searchAutoComplete: function (newValue, oldValue) {
      if (!this.searchLocation) {
        let key = process.env.VUE_APP_GOOGLE_MAP_API_KEY
        this.loadScriptMap(key)
        if (this.orderType === this.$const.DELIVERY) {
          this.searchByAddress()
          this.outletAddressOrigin = null
        } else {
          this.initAutoCompleteSearch()
        }
      }
      if (!this.searchAutoComplete) {
        this.outletList = []
        this.is_not_fount = false
      }
    },
    outletList: function (newValue, oldValue) {
      if (this.mapOpen) {
        this.resetMap()
        if (this.outletList.length > 0) {
          this.drawMaps()
        }
      }
    }
  },
  data () {
    return {
      errorPostalCode: '',
      highlight: null,
      locationCenter: {lat: 0, lng: 0},
      searchLocation: false,
      isDelivery: active,
      isCollection: inactive,
      orderType: this.$const.DELIVERY,
      isShow: this.checkPosition(this),
      searchAutoComplete: '',
      searchZipCode: '',
      outletList: [],
      addressList: [],
      mode: '',
      loadingFreeText: false,
      loadingZip: false,
      result: {},
      message: '',
      geocoder: {},
      map: {},
      marker: {},
      markers: [],
      addressAutoComplete: false,
      addressSelected: '',
      mapOpen: false,
      outletAddressOrigin: null,
      is_not_fount: false
    }
  },
  methods: {
    /**
     * Search stores by location
     * */
    searchByLocation (lat, lng) {
      this.locationCenter.lat = lat
      this.locationCenter.lng = lng
      this.mode = 'location'
      this.outletList = []
      this.addressList = []
      if (!lng || !lat) {
        return false
      }
      let val = lng + ',' + lat
      let data = {location: val, order_type: this.orderType.charAt(0), limit: 10}
      var vm = this
      vm.message = ''
      this.axios.apiOutlet.checkLocation(data, function (response) {
        vm.searchLocation = false
        vm.outletList = response.data.data.items
        if (vm.outletList.length <= 0) {
          vm.message = vm.$t('localization.stores_404') + ' ' + vm.$t(vm.orderType.toLowerCase()).toLowerCase()
        }
      }, function (error) {
        vm.searchLocation = false
        vm.message = vm.$t('localization.stores_404') + ' ' + vm.$t(vm.orderType.toLowerCase()).toLowerCase()
      })
    },
    /**
     * action when users clicked on list of outlet
     */
    onclickOutletAddressItem (outlet) {
      if (outlet) {
        if (this.orderType === this.$const.DELIVERY) {
          this.outletAddressOrigin = outlet
        } else {
          this.searchLocation = true
          this.searchAutoComplete = outlet.address
          this.getLatLog(outlet.address)
        }
      }
    },
    showHideMap (flag) {
      this.mapOpen = !flag
      if (this.mapOpen) {
        this.$modal.show(ModalMap, {outletList: this.outletList}, {height: 'auto'}, {
          'opened': this.beforeOpen,
          'before-close': this.beforeClose
        })
      } else {
        this.$root.$emit('onCloseMap')
      }
    },
    /**
     *  Intit google map
     * */
    initMap () {
      this.map = new google.maps.Map(document.getElementById('mapLocalization'), {
        zoom: 6,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        mapTypeControl: true
      })
    },
    initAutoCompleteSearch () {
      var vm = this
      let element = document.getElementById('address-autocomplete')
      if (typeof google !== 'undefined' && typeof (element) !== 'undefined' && element != null && !this.addressAutoComplete) {
        this.addressAutoComplete = new google.maps.places.Autocomplete(
          (document.getElementById('address-autocomplete')),
          {types: ['geocode'], componentRestrictions: {country: this.$const.COUNTRY_CODE}}
        )
        this.addressAutoComplete.addListener('place_changed', () => {
          if (this.addressAutoComplete) {
            let place = this.addressAutoComplete.getPlace()
            vm.searchAutoComplete = document.getElementById('address-autocomplete').value
            if (!place) {
              return
            }
            let lat = place.geometry.location.lat()
            let lng = place.geometry.location.lng()
            let myLatLng = lat + ',' + lng
            let objAddress = {
              address: 'My Location',
              latlng: myLatLng,
              lat: lat,
              lng: lng,
              place_id: place.place_id
            }
            vm.getDataFromGG(objAddress)
          }
        })
      }
    },
    /**
     * Get data from google
     * */
    getDataFromGG (address) {
      var vm = this
      if (address.address.length <= 0) { return false }
      if (typeof address.lat !== 'undefined' && typeof address.lng !== 'undefined') {
        vm.searchByLocation(address.lat, address.lng)
        return false
      }
      var data = {}
      data.address = (address.address || '')
      data.sensor = false
      data.key = this.$const.GOOGLE_MAP_API_KEY
      data.types = 'address'
      data.components = vm.$const.COUNTRY_CODE_COMPONENT // 'country:jp'
      if (typeof address.place_id !== 'undefined' && address.place_id.length > 0) {
        delete data.address
        delete data.types
        delete data.components
        delete data.latlng
        data.place_id = address.place_id
      } else if (typeof address.latlng !== 'undefined' && address.latlng.length > 0) {
        delete data.address
        delete data.types
        delete data.components
        data.latlng = address.latlng
      }
      delete this.axios.defaults.headers.common['Lang']
      delete this.axios.defaults.headers.common['Client']
      this.axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: data
      })
        .then(function (response) {
          vm.setData(response.data.results)
          let lat = vm.result.lat
          let lng = vm.result.long
          if (lat != '' && lng != '') {
            vm.searchByLocation(lat, lng)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          // always executed
          vm.axios.defaults.headers.common['Lang'] = vm.$i18n.locale
          vm.axios.defaults.headers.common['Client'] = vm.$session.set('client')
        })
    },
    /**
     * Click on Delivery tab
     * */
    clickActiveDelivery () {
      let input = document.getElementById('address-autocomplete')
      google.maps.event.clearInstanceListeners(input)
      this.addressAutoComplete = false
      this.orderType = this.$const.DELIVERY
      this.isDelivery = active
      this.isCollection = inactive
      this.message = ''
      this.addressList = []
      this.outletList = []
      this.searchByAddress()
    },
    /**
     * Click on Collection tab
     * */
    clickActiveCollection () {
      this.orderType = this.$const.CARRYOUT
      let key = this.$const.GOOGLE_MAP_API_KEY
      this.loadScriptMap(key)
      this.initGeocoder()
      this.initAutoCompleteSearch()
      this.isDelivery = inactive
      this.isCollection = active
      this.message = ''
      this.addressList = []
      this.outletList = []
      if (this.outletAddressOrigin && this.outletAddressOrigin.address) {
        // get lat lng and do search
        this.searchAutoComplete = this.outletAddressOrigin.address
        this.getLatLog(this.outletAddressOrigin.address)
      }
      this.outletAddressOrigin = null
    },
    infowindowClick (index) {
      let outlet = this.outletList[index]
      if (outlet) {
        this.clickChosenOutlet(outlet)
      }
    },
    onChangeOutlet (index) {
      for (let i in this.markers) {
        let url = this.markers[i].originIcon
        if (i === index) {
          url = this.markers[i].originIcon
        }
        this.markers[i].setIcon({
          url: url,
          scaledSize: new google.maps.Size(40, 40)
        })
      }
      if (this.markers.length > 0) {
        this.map.panTo(this.markers[index].position)
      }
    },
    /**
     * Using your location for show google map
     * */
    useMyLocation () {
      var vm = this
      vm.searchLocation = true
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          vm.getAddressByLatLog({lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude)})
          vm.searchByLocation(position.coords.latitude, position.coords.longitude)
          vm.locationCenter.lat = position.coords.latitude
          vm.locationCenter.lng = position.coords.longitude
        })
      }
    },
    initGeocoder () {
      if (typeof google !== 'undefined') {
        this.geocoder = new google.maps.Geocoder()
      }
    },
    /**
     * Get lat long from address
     * */
    getAddressByLatLog (latLong) {
      var vm = this
      vm.locationCenter.lat = latLong.lat
      vm.locationCenter.lng = latLong.lng
      this.initGeocoder()
      vm.searchLocation = true
      this.geocoder.geocode({'location': latLong}, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            vm.searchAutoComplete = results[0].formatted_address
          }
        }
      })
    },
    /**
     * Get lat long from address
     * */
    getLatLog (address) {
      var vm = this
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({'address': address}, function (results, status) {
        if (status === 'OK') {
          let lat = results[0].geometry.location.lat()
          let lng = results[0].geometry.location.lng()
          vm.searchByLocation(lat, lng)
        }
      })
    },
    /**
     * Chosen a address
     * */
    clickChosenOutlet (outlet) {
      this.result = {}
      if (outlet) {
        var vm = this
        vm.$bus.$emit('loading', 'block')
        vm.result.lat = outlet.lat
        vm.result.long = outlet.long
        vm.result.outletAddress = outlet.address
        vm.result.orderType = vm.$const.CARRYOUT
        vm.result.outletId = outlet.id
        vm.result.outletName = outlet.name
        vm.result.outletCode = outlet.code
        vm.result.tier = outlet.tier
        vm.checkValidOutlet(0)
      }
    },

    /**
     * Chosen a address
     *
     * */
    clickChosenAddress (outlet, order_now) {
      this.$v.outletAddressOrigin.$touch()
      if (this.$v.outletAddressOrigin.$pending || this.$v.outletAddressOrigin.$error) return

      this.result = {}
      if (outlet) {
        /**
         * this case for DELIVERY
         */
        if (this.orderType === this.$const.DELIVERY) {
          this.addressSelected = outlet.address
          if (outlet.storenumber && outlet.storenumber.trim().length > 0) {
            this.result.outletAddress = outlet.address
            this.result.orderType = this.$const.DELIVERY
            this.result.locationName = outlet.address
            this.result.prefectureName = outlet.prefecture_name
            this.result.cityName = outlet.city_name
            this.result.villeageName = outlet.villeage_name
            this.result.st = outlet.st
            this.result.outletId = outlet.storeid
            this.checkValidOutlet(order_now)
          } else {
            /**
             * handle process carryout
             * **/
            let geocoder = new google.maps.Geocoder()
            geocoder.geocode({'address': this.searchAutoComplete}, function (results, status) {
              if (status === 'OK') {
                let lat = results[0].geometry.location.lat()
                let lng = results[0].geometry.location.lng()
                this.searchOutletByLatLng(lat, lng)
              }
            })
          }
        } else {
          this.searchLocation = true
          this.searchAutoComplete = outlet.address
          this.getLatLog(outlet.address)
          /**
           * this case for CARRYOUT
           */
        }
      }
    },
    /**
     *  using for search list address by lat lng
     */
    searchOutletByLatLng (lat, lng) {
      var vm = this
      if (!lng || !lat) {
        vm.$modal.show(ModalDelivery, {message: false, methodSearch: vm.$const.DEFAULT_CONFIG_APP, hideCollectionButton: true})
        return false
      }
      let val = lng + ',' + lat
      let data = {location: val, order_type: 'C', limit: 10}
      vm.message = ''
      vm.axios.apiOutlet.checkLocation(data, function (response) {
        vm.searchLocation = false
        vm.outletList = response.data.data.items
        if (vm.outletList.length <= 0) {
          vm.$modal.show(ModalDelivery, {message: false, methodSearch: vm.$const.DEFAULT_CONFIG_APP, hideCollectionButton: true})
        } else {
          vm.$modal.show(ModalCarryout, {methodSearch: vm.$const.DEFAULT_CONFIG_APP, outletList: vm.outletList})
        }
      }, function (error) {
        console.log(`error: ${error}`)
        vm.$modal.show(ModalDelivery, {message: false, methodSearch: vm.$const.DEFAULT_CONFIG_APP, hideCollectionButton: true})
      })
    },
    /**
     * Checking outlet is available
     * */
    checkValidOutlet (order_now) {
      // check time outlet
      var vm = this
      let data = {
        order_type: this.orderType.charAt(0),
        order_time: this.convertDateTimeToUTC(this.$disposition.getByKey(this, 'collectionTimeText', '')),
        advance_order: this.$disposition.getByKey(this, 'advanceOrder', 0) || 0,
        outlet_id: this.result.outletId
      }
      if (order_now === 1) {
        data.order_time = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
        data.advance_order = 0
      }

      this.axios.apiOutlet.checkOutletAvailable(data,
        function (response) {
          response = response.data
          if (response.status) {
            let data = vm.$disposition.get(vm)
            response = response.data.item
            // set data
            data.prefectureName = vm.result.prefectureName || ''
            data.cityName = vm.result.cityName || ''
            data.villeageName = vm.result.villeageName || ''
            data.st = vm.result.st || ''
            data.lat = response.lat
            data.long = response.long
            data.postalCode = response.zip
            data.locationName = vm.result.locationName
            data.outletAddress = response.address
            data.orderType = vm.result.orderType
            data.outletId = response.id
            data.outletName = response.name
            data.outletCode = response.code
            data.advanceOrder = vm.$disposition.getByKey(vm, 'advanceOrder', 0) || 0
            data.tier = response.tier
            vm.$disposition.set(vm, data)
            vm.$router.push({ 'name': 'category', 'params': { 'slug': vm.$const.MENU_DEFAULT } })
            vm.showHideMap(true)
          } else if (response.message && response.message != '') {
            vm.$notify({
              type: 'warn',
              title: response.message
            })
          }
        },
        function (error) {
          let message = false
          if (error.message) {
            message = error.message
          }
          vm.$modal.show(ConfirmOrderAdvance, {message: message, outletId: vm.result.outletId})
        }
      )
    },
    /**
     * Search stores by free text
     * */
    searchByAddress () {
      var ref = this
      this.addressList = []
      this.outletList = []
      if (!this.searchAutoComplete) {
        return false
      }
      this.mode = 'free'
      this.loadingFreeText = true
      ref.message = ''
      this.axios.apiOutlet.getNextLevelData({address: this.searchAutoComplete, order_type: 'D', limit: 10, client_id: 1}, function (response) {
        ref.addressList = response.data.data.items
        if (ref.addressList.length <= 0 && !ref.is_not_fount) {
          ref.is_not_fount = true
          ref.$notify({
            type: 'warn',
            title: ref.$t('localization.stores_404') + ' ' + ref.$t(ref.orderType.toLowerCase()).toLowerCase()
          })
        }
        ref.loadingFreeText = false
      }, function (error) {
        ref.$notify({
          type: 'warn',
          title: ref.$t('localization.stores_404') + ' ' + ref.$t(ref.orderType.toLowerCase()).toLowerCase()
        })
        ref.loadingFreeText = false
      })
    },
    /**
     * Search stores by zip code
     * */
    searchByZipCode () {
      var zipcode = ''
      this.errorPostalCode = ''
      this.mode = 'zip'
      this.addressList = []
      this.outletList = []
      zipcode = this.searchZipCode.replace('-', '').replace(/[\uff01-\uff5e]/g,
        function (ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0) }
      )
      if (!this.searchZipCode) {
        this.errorPostalCode = 'message_empty_postcode'
        return false
      }
      if (zipcode.length != 7) {
        this.errorPostalCode = 'message_invalid_postcode'
        return false
      }
      var vm = this
      this.loadingZip = true
      vm.message = ''
      this.$bus.$emit('loading', 'block')
      this.axios.apiOutlet.getOutletByAddressCode({zip_code: zipcode}, function (response) {
        vm.loadingZip = false
        if (response.data.data.items.length <= 0) {
          vm.message = vm.$t('localization.zipcode.stores_404')
        } else {
          vm.$router.push({'name': 'test-localization', params: {zip: zipcode}})
        }
        vm.$bus.$emit('loading', 'none')
      }, function (error) {
        console.log(`error: ${error}`)
        vm.loadingZip = false
        vm.message = vm.$t('localization.zipcode.stores_404')
        vm.$bus.$emit('loading', 'none')
      })
    },
    /**
     * load current position on googlemap
     */
    drawCurrentPossition () {
      var vm = this
      // load current position
      if (vm.locationCenter.lat != 0 && vm.locationCenter.lng != 0) {
        let latlng = new google.maps.LatLng(vm.locationCenter.lat, vm.locationCenter.lng)
        let nMarket = new google.maps.Marker({
          position: latlng,
          map: vm.map,
          zoom: 6,
          draggable: true,
          icon: {url: 'https://static-ph1.s3-ap-southeast-1.amazonaws.com/ph/icons/map-location-pin.png', scaledSize: new google.maps.Size(50, 70)}
        })
        nMarket.addListener('dragend', function (e) {
          var point = nMarket.getPosition()
          vm.searchByLocation(point.lat(), point.lng())
          vm.map.panTo(point)
          vm.map.setCenter(nMarket.getPosition())
        })
      }
    },
    resetMap () {
      let vm = this
      for (let i = 0; i < vm.markers.length; i++) {
        vm.markers[i].setMap(null)
      }
      vm.markers = []
    },

    /**
     * Draw maps
     */
    drawMaps () {
      let vm = this
      // reset makers
      vm.resetMap()
      let bounds = new google.maps.LatLngBounds()
      for (let i = 0; i < this.outletList.length; i++) {
        let latlng = new google.maps.LatLng(this.outletList[i].lat, this.outletList[i].long)
        let index = i + 1
        let icon = {
          url: 'https://static-ph1.s3-ap-southeast-1.amazonaws.com/ph/icons/map-ph-pin.png',
          scaledSize: new google.maps.Size(50, 70)
        }
        let contentString = '<div id="content">' +
          '<div id="siteNotice">' +
          '</div>' +
          '<h1 id="firstHeading" class="firstHeading">' + this.outletList[i].name + '</h1>' +
          '<div id="bodyContent">' +
          '<p>' + this.outletList[i].address + '</p>' +
          '<p><button class="btn btn-success maps-infowindow t13 " style="padding: 8px 20px" onclick="infowindowClick(' + i + ')">' + this.$t('select') + '</button></p>' +
          '</div>' +
          '</div>'
        var infowindow = new google.maps.InfoWindow({
          content: contentString,
          maxWidth: 200
        })
        let marker = new google.maps.Marker({
          position: latlng,
          map: this.map,
          draggable: false,
          outletIndex: i,
          outlet: this.outletList[i],
          infowindow: new google.maps.InfoWindow({
            content: contentString
          }),
          title: this.outletList[i].address,
          icon: icon
        })
        this.markers.push(marker)
        bounds.extend(new google.maps.LatLng(this.markers[i].getPosition().lat(), this.markers[i].getPosition().lng()))
        marker.addListener('mouseover', function (e) {
          this.highlight = this.outletIndex
        })
        marker.addListener('click', function (e) {
          for (let i = 0; i < vm.markers.length; i++) {
            if (vm.markers[i].infowindow) {
              vm.markers[i].infowindow.close()
            }
          }
          vm.onChangeOutlet(this.outletIndex)
          this.infowindow.open(this.map, marker)
        })
        marker.addListener('mouseout', function (e) {
          this.highlight = null
        })
      }
      this.map.fitBounds(bounds)
    },
    loadScriptMap (key) {
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s); js.id = id
        js.src = 'https://maps.googleapis.com/maps/api/js?v=3&key=' + keApi + '&libraries=places&language=' + language + '&region=' + region
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'google-maps-jssdk'))
    },
    /**
     * Trigger before open modal popup
     * */
    beforeOpen (event) {
      this.initMap()
      this.drawCurrentPossition()
      this.drawMaps()
      this.onChangeOutlet(0)
      document.body.classList.add('modal-open')
    },
    /**
     * Trigger before close modal popup
     * */
    beforeClose (event) {
      this.mapOpen = false
      document.body.classList.remove('modal-open')
    }
  },
  components: {
    MaskedInput
  }
}
</script>
<style scoped>
.invalid-feedback p.error{
  color: red;
}
.contain-outlet{
  max-height: 520px;
  overflow-y: auto;
}
.disposition-title{
  font-size: 15px;
}
@media (min-width: 768px) {
  .disposition-title{
    font-size: 18px;
  }
}
#mapLocalization {
  height: 238px;
  width: 100%;
}
@media screen and (min-width: 48em){
  #mapLocalization {
    height: 455px;
  }
}
li.outlet-item:hover, .highlight{
  background: #3053691a;
}
.contain-outlet ul.list-outlet-detail {
	margin-left: -40px;
}
</style>
