<template>
  <div class="lg-container xs-my-15 hidden-xs hidden-sm">

    <div class="auth-box">
      <div class="item xs-px-10 t17">
        <span v-if="$auth.check() ">
          <router-link to="/profile" v-html="$t('full_name_format', {first_name:$auth.user().first_name, last_name:$auth.user().last_name})"></router-link>
        </span>
        <router-link v-else to="/login" class=""><img
                src="https://deo515rx9pz7c.cloudfront.net/fcds/icons/inactive.png" class="xs-mr-5">
          {{ $t("login_title") }}
        </router-link>
      </div>
      <div class="item slat"></div>
      <div class="item xs-px-10 t17">
        <span v-if="$auth.check() ">
          <a @click="logout()" href="javascript:void(0)" class="logout">{{$t('button_logout')}} </a>
        </span>
        <router-link v-else to="/register">
          {{$t('login_register')}}
        </router-link>
      </div>
    </div>

    <home-lang></home-lang>

<!--    <div class="xs-px-10 xs-my-15">-->
<!--      <button type="button" class="btn btn-default btn-ph-default text-bold" v-on:click="$router.push({name: 'tracking'})">-->
<!--        <img src="https://static.phdvasia.com/mx/icons/track_your_order.svg" class="xs-mr-5"><strong>{{-->
<!--        $t("track_your_order")}}</strong>-->
<!--      </button>-->
<!--    </div>-->
  </div>
</template>
<script>
import HomeLang from './HomeChangeLangues.vue'

export default {
  components: {
    HomeLang
  },
  mounted () {},
  methods: {
    logout: function () {
      this.$root.$emit('logout')
    }
  }
}

</script>
